/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Fullmap, FullmapWrap, FullmapCover, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-wzyd9t --style3 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4d9b27928999452d892cc1e8cca9e477_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4d9b27928999452d892cc1e8cca9e477_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4d9b27928999452d892cc1e8cca9e477_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4d9b27928999452d892cc1e8cca9e477_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4d9b27928999452d892cc1e8cca9e477_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4d9b27928999452d892cc1e8cca9e477_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4d9b27928999452d892cc1e8cca9e477_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/4d9b27928999452d892cc1e8cca9e477_s=3000x_.png);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":""}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s2 pb--0 pl--0 pr--0 pt--0" anim={"6"} animS={"2"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--102 w--900 title-box--invert swpf--uppercase" style={{"maxWidth":700}} content={"Samoobslužná<br>myčka aut"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 ff--1 fs--22 swpf--uppercase mt--30" use={"page"} href={"/#ahc8u1k4io7"} page={"32573"} content={"Kontaktovat"} section={"ahc8u1k4io7"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" anim={null} name={"informace"} style={{"backgroundColor":"rgba(244,251,255,1)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" anim={""} animS={"3"} columns={"2"}>
            
            <ColumnWrapper className="--shape5 pb--25 pl--25 pr--25 pt--25" style={{"maxWidth":"","backgroundColor":"var(--white)"}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">Základní mytí</span>"}>
              </Title>

              <Text className="text-box fs--22" content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">✔</span>&nbsp;</span> Mytí ráfků"}>
              </Text>

              <Text className="text-box fs--22 mt--02" content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">✔</span>&nbsp;</span> Hlavní mytí"}>
              </Text>

              <Text className="text-box fs--22 mt--02" content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">✔</span>&nbsp;</span>&nbsp;Měkký kartáč"}>
              </Text>

              <Text className="text-box fs--22 mt--02" content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">✔</span>&nbsp;</span> Oplach"}>
              </Text>

              <Text className="text-box fs--22 mt--02" content={"<span style=\"color: var(--color-custom-1);\"><span style=\"font-weight: bold;\">✔</span>&nbsp;</span> Leštění"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape5 --shadow5 pb--25 pl--25 pr--25 pt--25" style={{"maxWidth":"","backgroundColor":"var(--color-custom-1)"}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">Prémiové mytí</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">Všechno to, co je v základním mytí, a navíc</span>"}>
              </Text>

              <Text className="text-box fs--22" content={"<span style=\"color: var(--white);\"><span style=\"font-weight: bold;\">✔</span>&nbsp; XXL Aktivní pěna na uvolnění nečistot</span>"}>
              </Text>

              <Text className="text-box fs--22 mt--02" content={"<span style=\"color: var(--white);\"><span style=\"font-weight: bold;\">✔</span>&nbsp; Nanovosk</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" anim={null} name={"informace"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={""} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":894}}>
              
              <Text className="text-box fs--22" content={"Kontakt"}>
              </Text>

              <Title className="title-box fs--48 mt--08" style={{"maxWidth":890}} content={"<span style=\"color: var(--color-custom-1);\">+420 555 333 284<br>topmycka@topmycka.cz<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Fullmap className="--style2" name={"ahc8u1k4io7"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"14"} place={"Revoluční 149, 739 11 Frýdlant nad Ostravicí"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--10 pt--10" anim={null} name={"informace"} style={{"backgroundColor":"rgba(0,122,194,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={""} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/02a610587b5d4a7cb7055eea7a1f15a0.svg"} sizes={"100vw"} style={{"maxWidth":60}} srcSet={""}>
              </Image>

              <Title className="title-box fs--30 mt--06" content={"<span style=\"color: var(--white);\">TOPmyčka.cz</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}